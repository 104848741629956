export const GET_USER = 'GET_USER';
// import { ApiClient } from '../api/ApiClient';
// const client = new ApiClient();

export const getUser = () => async dispatch => {
  try {
    console.log('Masuk Get User')
    // const response = await client.get('/users/1');
    // const user = new User('response.data.id', 'response.data.name');
    const user = {id: 1, name: 'Pulu Pulu Merah'}

    setTimeout(() => {
      dispatch({
        type: GET_USER,
        payload: user
      });
    }, 3000);
  } catch (error) {
    console.error(error);
  }
  
};