import { GET_USER } from './actions'

const initialState = {
    dataDummy: null
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_USER:
        return {
          ...state,
          dataDummy: action.payload
        };
      default:
        return state;
    }
  };
  